.gallery-container {
    height: 70vh;
    background: linear-gradient(to right, rgb(0, 0, 0, .5), rgba(255,255,255,.3)), url(../../images/molcajete.jpg);
    background-position: center;
    background-size: cover;

    position: relative; /* Set position to relative for positioning children absolutely */
    overflow: hidden; /* Ensures nothing spills outside the container */
}   

.dishes {
    margin-left: 30px;
    margin-top: 50px;
    color: white;
    text-align: center;
}

.gallery-carousel {
    height: 40vh;
    cursor: grab;
    overflow: hidden;
    margin: 0 0;
    display: flex; /* Ensure this is flex to control the inner elements properly */
    align-items: center; /* This centers the items vertically */

    position: absolute; /* Absolute positioning */
    top: 50%; /* Start at 50% of the container's height */
    left: 0; /* Align to the left */
    right: 0; /* Align to the right */
    transform: translateY(-50%); /* Offset by half its height to center vertically */
    width: 100%;
}

.gallery-item {
    height: 250px; /* Adjust height based on your content needs */
    max-height: 250px; /* Minimum height for smaller screens or less content */
    max-width: 250px; /* Minimum width to ensure readability */
    width: 250px; /* Adjust width to fit your design */
    padding: 20px; /* Reduced padding for better space usage */
    margin: 10px; /* Spacing between items */
    border-radius: 10px; /* Smoother border radius */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    background-color: #fff; /* Light background for better readability */
    display: flex; /* Flex to better control inner content */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    flex-direction: column; 
    text-align: center; /* Center text */
    color: white; /* Darker text for contrast */
    font-size: 1.2rem; /* Larger font size for readability */

    background-size: cover !important; /* Ensures the entire image fits within the div */
    background-position: center !important; /* Centers the image in the div */
    background-repeat: no-repeat !important; 
}

.item-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.item-title {
    font-size: 1.5rem; /* Adjust size as needed */
    font-weight: bold; /* Makes the title stand out */
    margin-bottom: 0.5rem; /* Spacing between title and description */
}

.item-description {
    font-size: 1rem; /* Adjust size as needed */
    margin-bottom: -5rem; /* Spacing between description and price */
    text-shadow: 2px 4px 4px rgba(46,91,173,0.6);
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background for readability */
    border-radius: 1rem;
    width: 10em;
}

.item-price {
    font-size: 1.2rem; /* Adjust size as needed */
    font-weight: bold; /* Makes the price stand out */
}

.gallery-inner-carousel {
    margin-left: 20px;
    height: 40vh;
    display: flex;
    
}

@media (max-width: 768px) {

    .gallery-container {
        height: 60vh;
    }
    .inner-carousel {
        height: 30vh;
    }

    .gallery-item {
        height: 400px;
        width: 400px; /* Take more screen width on small devices */
        padding: 40px;
        font-size: 1rem; /* Slightly smaller font on small devices */
    }

    .item-description {
        font-size: 1rem; /* Adjust size as needed */
        margin-bottom: -8rem; /* Spacing between description and price */
        text-shadow: 2px 4px 4px rgba(46,91,173,0.6);
        background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background for readability */
        padding: 10px;
        border-radius: 1rem;
        position: absolute;
    }

}
